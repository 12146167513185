@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Plus+Jakarta+Sans:wght@400;500;600&display=swap");

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  margin: 0x auto 0 auto;
  background: rgba(246, 251, 255, 0.9);
  padding: 48px 0;
}

:root {
  --darkBlue: #05192f;
  --mainBlue: #2e5e92;
  --bgColor: #f6fbff;
  --maxContentWidth: 1110px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--bgColor);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--mainBlue);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--darkBlue);
}

button {
  background-color: none;
  border: none;
}

.logo {
  max-height: 40px;
  width: auto;
}

a {
  text-decoration: none;
}

a:hover {
  color: var(--mainBlue);
  transition: all ease 0.3s;
}

img {
  width: 100%;
  height: auto;
}
nav {
  width: 100%;
  margin: 0 auto;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  margin: 0px auto 0 auto;
  z-index: 1;

  position: fixed;
  top: 0;
  background: rgba(246, 251, 255, 0.95);
  backdrop-filter: blur(15px);
  padding: 32px 0;
}

.nav-content {
  max-width: var(--maxContentWidth);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.sm-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.sm-link {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--darkBlue);
}

/* Inter 56 Bold */
h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 5.6rem;
  line-height: 6.2rem;
}

.btn-primary {
  padding: 1rem 3.2rem;
  background: var(--mainBlue);
  box-shadow: 0 0.8rem 2.4rem rgba(46, 94, 146, 0.25);
  border-radius: 130px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.3rem;
  color: #ffffff;
  width: fit-content;
}

.btn-outline {
  padding: 1rem 3.2rem;
  border: 2px solid var(--darkBlue);
  filter: drop-shadow(0px 8px 24px rgba(46, 94, 146, 0.5));
  border-radius: 130px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.3rem;
  color: var(--darkBlue);
  width: fit-content;
  background-color: transparent;
}

.btn-primary:hover,
.btn-outline:hover {
  box-shadow: 0 0 2.4rem rgba(46, 94, 146, 0.5);
  cursor: pointer;
  transition: all ease 0.3s;
  background-color: var(--darkBlue);
  color: #fff;
}

.hero-section {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 0;
  background: url(assets/imgs/bg.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f6fbff;
}

.hero-section-content {
  max-width: var(--maxContentWidth);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 6.4rem;
  height: 100%;
  margin: 0 auto;
}

.hero-section-btns {
  display: flex;
  gap: 2.4rem;
}

.hero-section--title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 5.6rem;
  line-height: 6.2rem;
  color: var(--darkBlue);
  max-width: 480px;
}

.hero-section .hero-section--content {
  max-width: 41.2rem;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3.3rem;
  color: var(--darkBlue);
}

#OURPURPOSE {
  padding-top: 240px;
}

.purpose-section {
  display: flex;
  flex-direction: row;
  max-width: var(--maxContentWidth);
  margin: 0 auto 0 auto;
  justify-content: space-between;
  align-items: center;
}

.purpose-section-right {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.purpose-section-right img {
  width: 80px;
}

.purpose-section-right h3 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3.3rem;
  text-transform: uppercase;
  color: var(--darkBlue);
  margin: 0;
}

.purpose-section-right--header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.purpose-section-right-header {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.purpose-section-right-header h2 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 6rem;
  text-transform: capitalize;
  color: var(--darkBlue);
  margin: 0;
}

.purpose-section-cards {
  display: flex;
  gap: 24px;
  max-width: 664px;
}

.purpose-section-cards > div {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.purpose-section-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #eef6fe;
  padding: 40px 24px;
}

.purpose-section-card img {
  width: 48px;
  height: 48px;
}

.purpose-section-card h3 {
  margin: 0;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-transform: capitalize;
  color: var(--darkBlue);
}

.purpose-section-card p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--darkBlue);
  margin: 0;
}

.purpose-section-card div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

#OURPROCESS {
  padding-top: 240px;
}

.process-section {
  background-image: url(assets/imgs/shape1.png);
  max-width: var(--maxContentWidth);
  margin: 0 auto 0 auto;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}

.process-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.sebaSection-right .purpose-section-right {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.process-content-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.process-content-card--num {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 24px;
}

.process-content-card--num h2 {
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 4rem;
  line-height: 4.8rem;
  text-transform: uppercase;
  color: var(--darkBlue);
  transform: rotate(-90deg);
}

.process-content-card--num img {
  width: 2px;
  height: 80px;
}

.process-content-card h3 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3.3rem;
  text-transform: capitalize;
  color: var(--darkBlue);
  margin: 0;
  margin-bottom: 8px;
}

.process-content-card p {
  font-family: "Plus Jakarta Sans";
  max-width: 289px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--darkBlue);
  margin: 0;
}

.mb-140 {
  margin-bottom: 140px;
}

#MeetTeam {
  padding-top: 240px;
}

.sebaSection {
  margin: 0 auto 240px auto;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
}

.sebaSection-left {
  position: relative;
  top: 0;
  left: 0;
}

.sebaSection-left h3 {
  margin: 0;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.7rem;
  text-transform: capitalize;
  color: var(--darkBlue);
  margin-bottom: 8px;
}

.sebaSection-left h4 {
  margin: 0;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--darkBlue);
  margin-bottom: 16px;
}

.sebaSection-left p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: rgba(5, 25, 47, 0.75);
  margin-bottom: 32px;
}

.signature {
  width: 157.16px;
  height: 32px;
}

.seba-sm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.seba-sm div {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.seba-sm div img {
  width: 24px;
}

.seba {
  max-width: 600px;
}

.sebaSection-left-data {
  background: #eef6fe;
  box-shadow: 0px 0px 2px rgba(46, 94, 146, 0.25);
  border-radius: 8px;
  padding: 40px 24px;
  position: absolute;
  bottom: 16px;
  right: 0;
  max-width: 386px;
}

.danSection-left-data {
  background: #eef6fe;
  box-shadow: 0px 0px 2px rgb(46 94 146 / 25%);
  border-radius: 8px;
  padding: 40px 24px;
  position: absolute;
  bottom: -104px;
  max-width: 386px;
}

.sebaSection-right {
  max-width: 522px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}

.sebaSection-data p,
ul,
li {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: justify;
  color: var(--darkBlue);
  margin: 0;
}

.sebaSection-cta {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.PortflioSection {
  margin: 0 auto;
  max-width: var(--maxContentWidth);
  margin-bottom: 240px;
}

.PortflioSection h3 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 6rem;
  text-transform: capitalize;
  color: var(--darkBlue);
  margin: 0;
  text-align: center;
}

.PortflioSection p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  color: var(--darkBlue);
  margin: 0;
}

.PortflioSection--data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 56px;
}

footer {
  background-color: #eef6fe;
  padding: 72px 0;
}

.footer {
  max-width: var(--maxContentWidth);
  margin: 0 auto;
}

footer h6 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3.3rem;
  color: var(--darkBlue);
  margin: 0;
  margin-bottom: 16px;
}

footer address,
footer a {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3.3rem;
  color: var(--darkBlue);
}

.footer-logo {
  height: 40px;
  width: fit-content;
}

.footer-data {
  display: flex;
  flex-direction: row;
  gap: 148px;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-bottom {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  color: var(--darkBlue);
  text-align: center;
  margin-top: 88px;
}

.hamburger-menu,
.sidebar {
  display: none;
}

@media only screen and (max-width: 720px) {
  .nav-content .btn-primary {
    display: none;
  }
  .sebaSection-left-data,
  .danSection-left-data {
    display: none;
  }
  .nav {
    padding: 24px;
  }
  .nav button {
    display: none;
  }
  .sm-links {
    display: none;
  }
  .logo {
    max-height: 24px;
  }
  .hero-section-btns {
    align-items: center;
    flex-direction: column;
  }
  .hero-section-btns .btn-outline,
  .hero-section-btns .btn-primary {
    width: -webkit-fill-available;
    text-align: center;
  }

  .hero-section--title {
    font-size: 3.6rem;
    line-height: 4.6rem;
    max-width: 287px;
    width: 272px;
  }
  .purpose-section {
    flex-direction: column;
  }
  .hero-section {
    background-position: 58% bottom;
  }
  .hero-section .hero-section--content {
    max-width: 24.2rem;
  }
  .purpose-section-cards {
    flex-direction: column;
    margin-top: 40px;
  }
  .purpose-section-card {
    border-radius: 24px !important;
  }
  .purpose-section-right-header h2 {
    font-size: 3rem;
    line-height: 4.3rem;
  }
  .purpose-section-right h3 {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .purpose-section {
    padding: 0 24px;
    align-items: flex-start;
  }
  .purpose-section-right button {
    display: none;
  }
  .process-section {
    padding: 0 24px;
    background-image: none;
  }
  .mb-140 {
    margin-bottom: 80px;
  }
  .sebaSection {
    flex-direction: column-reverse;
    padding: 0 24px;
  }
  .sebaSection-left,
  .sebaSection-left-data {
    position: unset;
  }
  .dan {
    flex-direction: column !important;
  }
  .PortflioSection h3 {
    font-size: 3rem;
  }
  .PortflioSection {
    padding: 0 24px;
  }
  .PortflioSection--data {
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
  }
  .PortflioSection--data img {
    height: 24px;
  }
  .footer-content {
    flex-direction: column;
    padding: 0 24px;
  }
  .footer-data {
    flex-direction: column;
    gap: 48px;
    margin-top: 48px;
  }
}

@media only screen and (max-width: 1200px) {
  .nav-content .btn-primary {
    display: none;
  }
  .hamburger-menu {
    width: 32px;
    display: flex;
  }
  .hamburger-menu:hover {
    cursor: pointer;
  }
  .showMenu {
    display: flex !important;
    background-color: rgba(246, 251, 255, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    transition: all ease 0.3s;
  }
  .closeBtn {
    width: 32px;
    position: absolute;
    z-index: 13;
    top: 24px;
    right: 24px;
  }
  .showMenu .sm-link {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
  /**/
  .nav {
    padding: 24px;
  }
  .nav button {
    display: none;
  }
  .sm-links {
    display: none;
  }
  .logo {
    max-height: 24px;
  }
  .purpose-section {
    flex-direction: column;
  }
  .hero-section {
    background-position: 58% bottom;
  }
  .purpose-section-card {
    border-radius: 24px !important;
  }
  .purpose-section {
    padding: 0 24px;
    align-items: flex-start;
  }
  .hero-section-content {
    padding: 0 24px;
  }
  .purpose-section-right button {
    display: none;
  }
  .process-section {
    padding: 0 24px;
  }
  .mb-140 {
    margin-bottom: 80px;
  }
  .sebaSection {
    padding: 0 24px;
  }
  .purpose-section-cards {
    display: flex;
    gap: 24px;
    max-width: unset;
    margin-top: 56px;
  }
  .PortflioSection h3 {
    font-size: 3rem;
  }
  .PortflioSection {
    padding: 0 24px;
  }
  .PortflioSection--data {
    flex-wrap: wrap;
    gap: 64px;
    justify-content: center;
  }
  .PortflioSection--data img {
    height: 32px;
  }
  .footer-content {
    flex-direction: column;
    padding: 0 24px;
    justify-content: center;
    align-items: center;
  }
  .footer-data {
    flex-direction: column;
    gap: 48px;
    margin-top: 48px;
  }
  .sebaSection-left-data,
  .danSection-left-data {
    display: none;
  }
}

@media only screen and (max-width: 1056px) {
  .sebaSection {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 24px;
  }
  .sebaSection-right {
    max-width: unset;
  }
  .sebaSection-left,
  .sebaSection-left-data {
    position: unset;
  }
  .dan {
    flex-direction: column !important;
    align-items: center;
  }
}

@media only screen and (max-width: 420px) {
  .nav {
    width: -webkit-fill-available;
  }

  .hero-section--title {
    font-size: 2.4rem;
    line-height: 3.6rem;
    max-width: 192px;
    width: 272px;
  }
  .hero-section {
    background-position: 55% bottom;
  }
  .hero-section .hero-section--content {
    max-width: 20.2rem;
  }
  .hero-section-content {
    gap: 3.4rem;
  }
  .btn-primary,
  .btn-outline {
    padding: 0.8rem 3.2rem;
    font-size: 1.6rem;
    line-height: 3rem;
  }
  .purpose-section-right h3 {
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
  .purpose-section-right-header h2 {
    font-size: 2rem;
    line-height: 3.3rem;
  }
  .purpose-section-right {
    gap: 24px;
  }
  .purpose-section-card img {
    width: 40px;
    height: 40px;
  }
  .process-content-card--num h2 {
    font-size: 3rem;
    line-height: 1.8rem;
  }
  .sebaSection-cta {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .sebaSection-cta {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .sebaSection-cta .btn-outline,
  .sebaSection-cta .btn-primary {
    width: auto;
    text-align: center;
  }
}

@media only screen and (max-width: 320px) {
  .hero-section-btns .btn-outline {
    display: none;
  }
}

@media (orientation: landscape) and (min-device-width: 768px) {
  .hero-section-content {
    gap: 1rem;
  }
  .hero-section--title {
    font-size: 3.8rem;
    line-height: 4.8rem;
    max-width: 360px;
  }
  .hero-section .hero-section--content {
    max-width: 36.2rem;

    font-size: 1.6rem;
    line-height: 3rem;
  }
}
